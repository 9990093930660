import React, { useState } from 'react';
import {useDispatch} from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import LogoIcon from '../../../img/logo-icon.svg';
import LogoText from '../../../img/logo-text.svg';
import WhiteLogo from '../../../../public/images/TT_Wordmark_White.png';
import { useMeQuery } from '../../services/User';
import Menu from './Menu';
import classes from './Sidebar.module.scss';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import PopupAccount from '../PopupAccount/PopupAccount';
import {GoogleCalendarApi} from '../../services/GoogleCalendarApi'
import {GoogleDriveApi} from '../../services/GoogleDriveApi'
import {Api} from '../../services/Api'
import {removeGoogleToken, removeUserBearer} from '../../utils/user'

const Sidebar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: me } = useMeQuery();
  const [openMenu, setOpenMenu] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const logout = () => {
    removeUserBearer()
    removeGoogleToken()
    history.push('/login')

    Array.from([Api, GoogleDriveApi, GoogleCalendarApi]).forEach((api) => {
      dispatch(api.util.resetApiState())
    })
  }

  return (
    <>
      <header className={classes.MobileHeader}>
        <div className={classes.LogoAreaMobile}>
          <a href="/" className={classes.LogoSidebar}>
            <img src={WhiteLogo} alt="Logo" />
          </a>
        </div>
        <div className={classes.HamburgerIcon} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        </div>
      </header>

      <div
        className={`${classes.Sidebar} ${isMobileMenuOpen ? classes.Open : ''}`}
        data-toggle={openMenu}
      >
        <div className={classes.LogoArea}>
          <a href="/" className={classes.LogoSidebar}>
            <img src={WhiteLogo} alt="Logo" />
          </a>
        </div>
        <Menu role={me.role} />
       
        <NavLink to="/help-center" className="btn btn-sm btn-tr mt-auto">
          <i className="icon-help mr-2" />
          <span>Help</span>
        </NavLink>
        <button type='button' className="btn-sm mt-3" style={{backgroundColor: "white", borderRadius: 10, display: 'inline'}} onClick={logout}>
          <i className="icon-logout mr-2" />
          <span>Sign out</span>
        </button>
      </div>
    </>
  );
};

export default Sidebar;