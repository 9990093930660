import React from 'react'
import PropTypes from 'prop-types'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import classes from './MainLayout.module.scss'

const MainLayout = ({children, pageName, pageTitle}) => (
    <div className={classes.MainLayout}>
      <Sidebar />
      <div className={classes.Content}>
        <Header pageName={pageName} pageTitle={pageTitle} />
        <div className={classes.Container} pageTitle={pageTitle}>
          {children}
        </div>
      </div>
    </div>
  )

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
}

export default MainLayout




