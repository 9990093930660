import React from 'react'
import {useModal} from '../../hooks/useModal'
import Modal from '../../components/Modal/Modal'
import {useReportIssueMutation} from '../../services/Report'
import {useForm} from 'react-hook-form'
import Spinner from '../Spinner/Spinner'

const ReportIssue = () => {
  const {openModal, closeModal, modalState} = useModal()
  const [sendReport, result] = useReportIssueMutation()
  const {register, handleSubmit, formState: {errors}} = useForm()


  const onSubmit = async (data) => {
    try {
      const invoiceResponse = await sendReport(data).unwrap()

    } catch (err) {

    }
  }

  return (
    <>

      <button type="button" className="btn btn-red btn-md mr-2" onClick={openModal}>
        <i className="icon-alert-triangle text-xs	mr-2"></i>
        Report an issue
      </button>
      <Modal modalState={modalState} close={closeModal} extraClass="!max-w-1.5xl">
        {result?.isSuccess &&
          <div className="text-center py-14">
            <h2 className="mb-2">Thank you for reporting this issue!</h2>
            <p>We will review the issue you reported and action will be taken.</p>
          </div>
        }
        <form className={result?.isSuccess ? "hidden" : ""} onSubmit={handleSubmit(onSubmit)}>
          <div className="text-center mb-10">
            {result?.isLoading && <Spinner/>}
            {!result?.isLoading && <i className="icon-alert-triangle text-3xl"></i>}

            <h1>Report Issue</h1>
            <p>Please help us understand what's happening.</p>
          </div>
          <div className="form-row !mb-4">
            <label>Subject</label>
            <input
              type="text"
              {...register('subject', {required: true, maxLength: 30})}
              className={errors.subject ? "form-error" : ""}
            />
          </div>
          <div className="form-row !mb-4">
            <label>Details</label>
            <textarea
              placeholder="Provide some details"
              {...register('details', {required: true})}
              className={errors.details ? "form-error" : ""}
            />
          </div>
          <div className="flex justify-end gap-4 mt-8">
            <button type="button" className="btn btn-lg btn-tr" onClick={closeModal}>
              Cancel
            </button>
            <button type="submit"  className="btn btn-lg btn-bl">
              Send
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ReportIssue
