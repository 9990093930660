// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../img/background-main.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ltFb1JtCcE2a1AL6dHh0yQ\\=\\={background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top no-repeat;background-size:cover;display:flex;flex-direction:row;min-width:1200px;padding:1.5rem}@media print{.ltFb1JtCcE2a1AL6dHh0yQ\\=\\={background:none;padding:0}}.bSJ5IBNy76HlLcep7WgUzw\\=\\={display:flex;flex-direction:column;padding-left:2rem;width:100%}@media print{.bSJ5IBNy76HlLcep7WgUzw\\=\\={padding-left:0}}.GoBsid4OILUj8rqiLkCq5w\\=\\={max-width:1100px;padding-bottom:2rem;padding-top:2rem;width:100%}", "",{"version":3,"sources":["webpack://./resources/js/components/MainLayout/MainLayout.module.scss"],"names":[],"mappings":"AAAA,4BACE,gEAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CAEA,gBAAA,CADA,cAEF,CACE,aARF,4BASI,eAAA,CACA,SAEF,CACF,CAEE,4BAAA,YAAA,CACA,qBAAA,CAEA,iBAAA,CADA,UAFA,CAKA,aACE,4BAAA,cAAA,CAIJ,CACE,4BACA,gBAAA,CADA,mBAAA,CAAA,gBAAA,CAEA,UAFA","sourcesContent":[".MainLayout {\n  background: url(../../../img/background-main.svg) top center no-repeat;\n  background-size: cover;\n  @apply flex;\n  @apply flex-row;\n  @apply p-6;\n  min-width: 1200px;\n  \n  @media print {\n    background: none;\n    @apply p-0;\n  }\n}\n\n.Content {\n  @apply flex;\n  @apply flex-col;\n  @apply w-full;\n  @apply pl-8;\n  \n  @media print {\n    @apply pl-0;\n  }\n}\n\n.Container {\n  @apply py-8;\n  @apply max-w-container;\n  @apply w-full;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainLayout": "ltFb1JtCcE2a1AL6dHh0yQ==",
	"Content": "bSJ5IBNy76HlLcep7WgUzw==",
	"Container": "GoBsid4OILUj8rqiLkCq5w=="
};
export default ___CSS_LOADER_EXPORT___;
