import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import LogoIcon from '../../../img/logo-icon.svg'
import LogoText from '../../../img/logo-text.svg'
import {useMeQuery} from '../../services/User'
import Menu from './Menu'
import classes from './Sidebar.module.scss'

const Sidebar = () => {
  const {data: me} = useMeQuery()
  const [openMenu, setOpenMenu] = useState(true)
  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }
  return (
    <div className={classes.Sidebar} data-toggle={openMenu}>
      <div className={classes.LogoArea}>
        <a href="/" className={classes.LogoSidebar}>
          <img src={LogoIcon} alt="" />
          <img src={LogoText} alt="" />
        </a>
        <button type="button" className="btn-toggle-menu" onClick={toggleMenu}>
          <i className="icon-chevron-left text-xs" />
        </button>
      </div>
      <Menu role={me.role} />
      <NavLink to="/help-center" className="btn btn-sm btn-tr mt-auto">
        <i className="icon-help mr-2" />
        <span>Help</span>
      </NavLink>
    </div>
  )
}

export default Sidebar
