// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ltFb1JtCcE2a1AL6dHh0yQ\\=\\={background-color:#f2f2f2;display:flex;flex-direction:row;padding:1.5rem}@media print{.ltFb1JtCcE2a1AL6dHh0yQ\\=\\={background:none;padding:0}}.bSJ5IBNy76HlLcep7WgUzw\\=\\={display:flex;flex-direction:column;padding-left:2rem;width:100%}@media print{.bSJ5IBNy76HlLcep7WgUzw\\=\\={padding-left:0}}.GoBsid4OILUj8rqiLkCq5w\\=\\={max-width:1100px;padding-bottom:2rem;padding-top:2rem;width:100%}@media (max-width:768px){.ltFb1JtCcE2a1AL6dHh0yQ\\=\\={padding:0}.bSJ5IBNy76HlLcep7WgUzw\\=\\={display:flex;justify-content:center;margin-left:.5rem;margin-right:.5rem;padding-left:.25rem;padding-right:.25rem}}", "",{"version":3,"sources":["webpack://./resources/js/components/MainLayout/MainLayout.module.scss"],"names":[],"mappings":"AAAA,4BAGE,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,cADF,CAKE,aAVF,4BAWI,eAAA,CACA,SAFF,CACF,CAME,4BAAA,YAAA,CACA,qBAAA,CAEA,iBAAA,CADA,UAFA,CAKA,aACE,4BAAA,cAAA,CAAJ,CAKE,4BACA,gBAAA,CADA,mBAAA,CAAA,gBAAA,CAEA,UAFA,CAKF,yBAEE,4BACE,SAFF,CAME,4BAIA,YAAA,CACA,sBAAA,CAHA,iBAAA,CACA,kBAAA,CAHA,mBAAA,CACA,oBADA,CAGJ","sourcesContent":[".MainLayout {\n  // background: url(../../../img/background-main.svg) top center no-repeat;\n  // background-size: cover;\n  background-color: #F2F2F2;\n  @apply flex;\n  @apply flex-row;\n  @apply p-6;\n  // min-width: 1200px;\n  // border: 1px solid purple;\n  \n  @media print {\n    background: none;\n    @apply p-0;\n  }\n}\n\n.Content {\n  @apply flex;\n  @apply flex-col;\n  @apply w-full;\n  @apply pl-8;\n  \n  @media print {\n    @apply pl-0;\n  }\n}\n\n.Container {\n  @apply py-8;\n  @apply max-w-container;\n  @apply w-full;\n}\n\n@media (max-width: 768px) {\n  \n  .MainLayout {\n    padding: 0;\n  }\n\n  .Content {\n    @apply pl-1;\n    @apply pr-1;\n    @apply ml-2;\n    @apply mr-2;\n    display: flex;\n    justify-content: center;\n  }\n \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainLayout": "ltFb1JtCcE2a1AL6dHh0yQ==",
	"Content": "bSJ5IBNy76HlLcep7WgUzw==",
	"Container": "GoBsid4OILUj8rqiLkCq5w=="
};
export default ___CSS_LOADER_EXPORT___;
