import React from 'react'
import {useDispatch} from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import {useUserRole} from '../../hooks/useUserRole'
import {Api} from '../../services/Api'
import {GoogleCalendarApi} from '../../services/GoogleCalendarApi'
import {GoogleDriveApi} from '../../services/GoogleDriveApi'
import {removeGoogleToken, removeUserBearer} from '../../utils/user'
import classes from './PopupAccount.module.scss'

const PopupAccount = () => {
  const history = useHistory()
  const {isCoach} = useUserRole()
  const dispatch = useDispatch()

  const logout = () => {
    removeUserBearer()
    removeGoogleToken()
    history.push('/login')

    Array.from([Api, GoogleDriveApi, GoogleCalendarApi]).forEach((api) => {
      dispatch(api.util.resetApiState())
    })
  }

  return (
    <div className={classes.Modal}>
      {isCoach &&
        <NavLink to="/my-account">
          <i className="icon-settings" />
          <span>Settings</span>
        </NavLink>
      }
      <NavLink to="/help-center">
        <i className="icon-question-mark" />
        <span>FAQ</span>
      </NavLink>
      <button type='button' onClick={logout}>
        <i className="icon-logout" />
        <span>Sign out</span>
      </button>
    </div>
  )

}

export default PopupAccount
