import React, {lazy, Suspense} from 'react'
import Spinner from "../Spinner/Spinner";
import {useUserRole} from '../../hooks/useUserRole'

const HeaderComponents = ({pageName, pageTitle}) => {
  const {isAdmin, isCoach, isStudent} = useUserRole()
  if (pageName === 'HomePage') {
    if (isAdmin) {
      pageName = 'Coaches'
    }
    if (isCoach) {
      pageName = 'CoachDashboard'
    }
    if (isStudent) {
      pageName = 'Dashboard'
    }
  }

  const DynamicHeader = lazy(() => import(`../../pages/${pageName}/Header` ).catch(() => ({
    default: () => <div></div>
  })))
  return (
    <Suspense fallback={<Spinner/>}>
      <DynamicHeader/>
    </Suspense>

  )
}

export default HeaderComponents
